<template>
	<div>
		<div class="form-group">
			<label>{{ $t('global.recherche_adresse') }}</label>
			<vue-bootstrap-typeahead 
				v-model="query"
				:data="query_result"
				:serializer="item => item.place_name"
				:minMatchingChars="1"
				:matched="false"
				@hit="select_address"
			>
				<template slot="prepend">
					<b-input-group-text><font-awesome-icon :icon="['fal', 'search']" /></b-input-group-text>
				</template>
				<!-- <template slot="append">
					<b-button variant="secondary" @click="loadAddress">{{ $t('global.rechercher') }}</b-button>
				</template> -->
			</vue-bootstrap-typeahead>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import MapBox from "@/mixins/MapBox.js"
	import Countries from '@/assets/lang/countries/countries'

	export default {
		name: 'EAddress',
		mixins: [MapBox],
		props: {
			address: {
				type: String,
				default: () => ''
			},
			postalCode: {
				type: String,
				default: () => ''
			},
			city: {
				type: String,
				default: () => ''
			},
			country: {
				type: [String, Number],
				default: () => ''
			},
		},
		data () {
			return {
                query: '',
				address_selected: {},
				query_result: [],
				mapbox_key: null,
				from_tiers: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.mapbox_key = this.getMapBoxKey()
			},

			async loadAddress() {
				const res = await this.searchAddress(this.query, this.mapbox_key)
				if(res) {
					if(this.from_tiers && res.features.length > 0) {
						this.select_address(res.features[0])
					}
					else {
						this.query_result = res.features
					}
				}
			},

			search(params) {
				this.from_tiers = true
				this.query = params
			},

			select_address(selected) {
				let address = '', postcode = '', city = '', country = ''

				if(selected.place_type[0] == 'address' || selected.place_type[0] == 'poi') {
					address = selected.properties.address ? selected.properties.address + ", " + selected.text : selected.address + ' ' + selected.text
					postcode = selected.context.find(ad => ad.id.includes('postcode')).text
					city = selected.context.find(ad => ad.id.includes('place')).text
					country = selected.context.find(ad => ad.id.includes('country')).text
				} else if(selected.place_type[0] == 'place') {
					city = selected.text
					country = selected.context.find(ad => ad.id.includes('country')).text
				} else if(selected.place_type[0] == 'region') {
					country = selected.context.find(ad => ad.id.includes('country')).text
				}

				const countries = Countries['en'].label
				const country_id = Object.keys(countries).find(key => countries[key] === country);


				this.$emit('update:address', address)
				this.$emit('update:postalCode', postcode)
				this.$emit('update:city', city)
				if(country_id) {
					this.$emit('update:country', country_id)
				}

				this.$emit('update:lng', selected.geometry.coordinates[0])
				this.$emit('update:lat', selected.geometry.coordinates[1])

				this.from_tiers = false
			}
		},

		watch: {
			query(val) {
				this.loadAddress()
			}
		},

		components: {
            VueBootstrapTypeahead: () => import("GroomyRoot/components/Utils/CustomTypeahead/VueBootstrapTypehead")
        }
	}

</script>