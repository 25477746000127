var MapBoxMixin = {
	data() {
		return {
			base_url: 'https://api.mapbox.com/geocoding/v5/mapbox.places/:query'
		}
	},
	methods: {
		searchAddress: async function(query, api_key) {
			if(!api_key || query === '') return false

			const params = {'query': query}
			const url = this.constructRoute(this.base_url, params)  + ".json?access_token=" + api_key

			const result = await this.$request.request_get_api("MapBoxMixin::searchAddress", url)
			.catch(error => {
				console.error("MapBoxMixin::searchAddress => ERROR", error)
				return null
			})
			
			return result
		},
	}
}

export default MapBoxMixin